import {Component, Provide, Vue,Watch} from 'vue-property-decorator';
import common from '../../Common/Index.vue'
import {
  AcceptOrderApi,
  CancelOrderApi, ConfirmReceiptApi,
  DeleteOrderApi,
  GetByPageOrderApi,
  RefundOrderApi
} from "@/network/modules/mine";
import {GetByPageOrderReq, OrderIdReq} from "@/interface/res/mine";
import OrderItem from "@/views/Mine/Components/OrderItem.vue";
import {mapGetters} from "vuex";

@Component({
  components: {
    common,
    OrderItem
  },
 computed: {
   ...mapGetters(['orderStatuMsg'])
 }
})
export default class Order extends Vue {
  @Provide() titleList:Array<{id:number,name:string}> = [
    {id:-1,name:'所有定单'},
    {id:0,name:'待付款'},
    {id:3,name:'待发货'},
    {id:4,name:'待收货'},
    {id:6,name:'已完成'}
  ]
  @Provide() titleIndex:number = 0
  @Provide() condition:GetByPageOrderReq = {
    // orderStatus:1,
    pageNum:1,
    pageSize:6,
  }
  @Provide() list:Array<any> = []
  @Provide() listTotal:number = -1
  @Provide() loading:boolean = false
  @Provide() item:object = {}
  @Provide() dialogShow:boolean = false
  @Provide() dialogType:number = 0
  @Provide() dialogLoading:boolean = false

  @Watch('orderStatuMsg',{immediate:false, deep:false})
  private orderChange():void {
    const _this:any = this;
	_this.GetByPageOrderFn();
  }
  created(){
    const _this:any = this;
    _this.GetByPageOrderFn()
  }

  /**
   * @Author HS
   * @Date 2021/8/4 9:51 上午
   * @Description: 获取定单列表
   * @Params: null
   * @Return: null
  */
  async GetByPageOrderFn(){
    const _this:any = this;
    _this.loading = true;
    if(_this.condition.pageNum == 1){
      _this.listTotal = -1
      _this.list = []
    }
    let params:GetByPageOrderReq = {..._this.condition}
    const res = await GetByPageOrderApi(params)
    let a = setTimeout(()=>{_this.loading = false;clearTimeout(a)},500)
    const { data, code, total, msg } = res
    if(code != 200 ){
      _this.$message.error(msg);
      return
    }
    _this.list = [..._this.list,...data]
    _this.listTotal = total
  }


  /**
   * @Author HS
   * @Date 2021/8/4 9:51 上午
   * @Description: 类型切换
   * @Params: { number ： index - true } [下标]
   * @Return: null
   */
  typeChangeFn(index:number){
    const _this:any = this;
    _this.titleIndex = index
    if(index == 0){
      delete _this.condition.orderStatus
    }
    else{
      _this.condition.orderStatus = _this.titleList[index].id
    }
    _this.condition.pageNum = 1
    _this.list = []
    _this.listTotal = -1
    _this.GetByPageOrderFn()
  }

  /**
   * @Author HS
   * @Date 2021/8/4 9:51 上午
   * @Description: 点击查看更多
   * @Params: null
   * @Return: null
   */
  loadingMoreFn(){
    const _this:any = this;
    _this.condition.pageNum++
    _this.GetByPageOrderFn()
  }


  /**
   * @Author HS
   * @Date 2021/8/5 11:16 上午
   * @Description: 定单弹窗
   * @Params: { object ： item - false } [当前数据]
   * @Params: { number ： iType - true } [类型:1-删除 2-退款 3-接受延时 4-定单详情 5-取消定单 6-联系客服 7-确认收货 8-立即付款 9-查看物流]
   * @Return: null
  */
  dialogCallbackFn(item:any,iType:number){
    console.log(iType);
    const _this:any = this;
    _this.item = item
    if(iType == 1 || iType == 2 || iType == 3 || iType == 5 || iType == 7){
      _this.dialogShow = true
      _this.dialogType = iType
      return
    }
    switch (iType) {
      case 4:
        _this.goRouter('orderDetail')
        break;
      case 6:
        _this.contactCustomerServiceFn()
        break;
      case 8:
        _this.settlementFn(item.orderId)
        break;
      case 9:
        _this.goRouter('orderLogistics')
        break;
    }


  }

  /**
   * @Author HS
   * @Date 2021/8/5 2:16 下午
   * @Description: 弹窗确认回调
   * @Params: null
   * @Return: null
  */
  confirmCallbackFn(){
    const _this:any = this;
    switch (_this.dialogType) {
      case 1:
        _this.DeleteOrderFn()
        break;
      case 2:
        _this.RefundOrderFn()
        break;
      case 3:
        _this.AcceptOrderFn()
        break;
      case 5:
        _this.CancelOrderFn()
        break;
      case 7:
        _this.ConfirmReceiptFn()
        break;

    }
  }

  /**
   * @Author HS
   * @Date 2021/8/5 11:16 上午
   * @Description: 确认收货
   * @Params: null
   * @Return: null
   */
  async ConfirmReceiptFn(){
    const _this:any = this;
    _this.dialogLoading = true
    let params:OrderIdReq = {
      orderId:_this.item.orderId
    }
    const res = await ConfirmReceiptApi(params)
    let a = setTimeout(()=>{_this.dialogLoading = false;clearTimeout(a)},500)
    const { code, msg } = res
    if(code != 200 ){
      _this.$message.error(msg);
      return
    }
    _this.list.map((item:any) => {
      if(item.orderId == _this.item.orderId){
        item.orderStatus = 5
      }
    })
    _this.dialogShow = false
    _this.dialogType = 0
  }

  /**
   * @Author HS
   * @Date 2021/8/5 11:16 上午
   * @Description: 接受延时
   * @Params: null
   * @Return: null
   */
  async AcceptOrderFn(){
    const _this:any = this;
    _this.dialogLoading = true
    let params:OrderIdReq = {
      orderId:_this.item.orderId
    }
    const res = await AcceptOrderApi(params)
    let a = setTimeout(()=>{_this.dialogLoading = false;clearTimeout(a)},500)
    const { code, msg } = res
    if(code != 200 ){
      _this.$message.error(msg);
      return
    }
    _this.list.map((item:any) => {
      if(item.orderId == _this.item.orderId){
        item.orderStatus = 2
      }
    })
    _this.dialogShow = false
    _this.dialogType = 0
  }


  /**
   * @Author HS
   * @Date 2021/8/5 11:16 上午
   * @Description: 删除定单
   * @Params: null
   * @Return: null
   */
  async DeleteOrderFn(){
    const _this:any = this;
    _this.dialogLoading = true
    let params:OrderIdReq = {
      orderId:_this.item.orderId
    }
    const res = await DeleteOrderApi(params)
    let a = setTimeout(()=>{_this.dialogLoading = false;clearTimeout(a)},500)
    const { code, msg, data } = res
    if(code != 200 ){
      _this.$message.error(msg);
      return
    }
    // let index = _this.list.findIndex((item:any) => item.orderId == _this.item.orderId)
    // _this.list.splice(index,1)
    _this.dialogShow = false
    _this.dialogType = 0
    _this.condition.pageNum = 1
    _this.GetByPageOrderFn()

  }

  /**
   * @Author HS
   * @Date 2021/8/5 11:16 上午
   * @Description: 定单退款
   * @Params: null
   * @Return: null
   */
  async RefundOrderFn(){
    const _this:any = this;
    _this.dialogLoading = true
    let params:OrderIdReq = {
      orderId:_this.item.orderId
    }
    const res = await RefundOrderApi(params)
    let a = setTimeout(()=>{_this.dialogLoading = false;clearTimeout(a)},500)
    const { code, msg, data } = res
    if(code != 200 ){
      _this.$message.error(msg);
      return
    }
    _this.list.map((item:any) => {
      if(item.orderId == _this.item.orderId){
        _this.$base.isNull(data) ? item.orderStatus = 11 : item.orderStatus = 10
      }
    })
    _this.dialogShow = false
    _this.dialogType = 0
  }

  /**
   * @Author HS
   * @Date 2021/8/5 11:16 上午
   * @Description: 取消定单
   * @Params: null
   * @Return: null
   */
  async CancelOrderFn(){
    const _this:any = this;
    _this.dialogLoading = true
    let params:OrderIdReq = {
      orderId:_this.item.orderId
    }
    const res = await CancelOrderApi(params)
    let a = setTimeout(()=>{_this.dialogLoading = false;clearTimeout(a)},500)
    const { code, msg, data } = res
    if(code != 200 ){
      _this.$message.error(msg);
      return
    }
    _this.list.map((item:any) => {
      if(item.orderId == _this.item.orderId){
        if(_this.item.orderStatus == 0){
          item.orderStatus = 12
        }
        else if(_this.item.orderStatus == 1){
          _this.$base.isNull(data) ? item.orderStatus = 11 : item.orderStatus = 12
        }
      }
    })
    _this.dialogShow = false
    _this.dialogType = 0
  }


  /**
   * @Author HS
   * @Date 2021/8/6 5:03 下午
   * @Description: 联系客服
   * @Params: null
   * @Return: null
   */
  contactCustomerServiceFn(){
    const _this:any = this;
    _this.$store.commit('SET_SERVICESHOW',true)
    _this.$store.commit('SET_UNREAD',0);
  }

  /**
   * @Author HS
   * @Date 2021/8/10 11:05 上午
   * @Description: 结算
   * @Params: { string ： id - true } [id]
   * @Return: null
   */
  settlementFn(id:string){
    const _this:any = this;
    const path = _this.$base.routeLinkSplice(_this.$IndexConfig.pay.path,'i='+id)
    console.log(path);
    _this.$base.goRouter(path)
  }

  /**
   * @Author HS
   * @Date 2021/8/5 2:51 下午
   * @Description: 跳转页面
   * @Params: { string ： iPath - true } [路径]
   * @Return: null
   */
  goRouter(iPath:string){
    const _this:any = this;
    const link = _this.$base.routeLinkSplice(_this.$MineConfig[iPath].path,"i="+_this.item.orderId.toString())
    _this.$base.goRouter(link)
  }

}
