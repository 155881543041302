
import { Component, Prop, Provide, Vue, Watch } from 'vue-property-decorator';
@Component({
  components: {
  },
  computed: {
  }
})
export default class OrderItem extends Vue {

  @Prop({ type: Object, default: () => { } }) readonly data: any;
  @Provide() title: string = ''
  @Provide() expirationTime: number = 0

  @Watch('data', { immediate: true, deep: true })
  private iValChange(): void {
    const _this: any = this
    if (_this.data.orderStatus == 0) {
      _this.title = '待付款'
      // _this.expirationTime = Math.floor((_this.data.expirationTime - new Date().getTime()) / 1000 / 60)
      _this.countDown(_this.data.expirationTime)
    }
    else if (_this.data.orderStatus == 1) {
      _this.title = '待付款（分笔支付）'
      _this.countDown(_this.data.expirationTime)
      // _this.expirationTime = Math.floor((_this.data.expirationTime - new Date().getTime()) / 1000 / 60)
    }
    else if (_this.data.orderStatus == 2 || _this.data.orderStatus == 3) {
      _this.title = '待发货'
    }
    else if (_this.data.orderStatus == 4) {
      _this.title = '待收货'
    }
    else if (_this.data.orderStatus == 5 || _this.data.orderStatus == 6) {
      _this.title = '已完成'
    }
    else if (_this.data.orderStatus == 7) {
      _this.title = '排单中'
    }
    else if (_this.data.orderStatus == 8) {
      _this.title = '排单延时'
      // _this.expirationTime = Math.floor((_this.data.expirationTime - new Date().getTime()) / 1000 / 60)
      _this.countDown(_this.data.expirationTime)
    }
    else if (_this.data.orderStatus == 9) {
      _this.title = '退款中...'
    }
    else if (_this.data.orderStatus == 10) {
      _this.title = '已退款'
    }
    else if (_this.data.orderStatus == 11) {
      _this.title = '退款失败'
    }
    else if (_this.data.orderStatus == 12) {
      _this.title = '交易关闭'
    }
  }
  created() {
    const _this: any = this;



  }
  mounted() {
    const _this: any = this;
  }
  countDown(endTimeStamp: number) {
    const _this: any = this;
    _this.expirationTimer = setTimeout(() => {
      let expirationTime = _this.$base.countDown(endTimeStamp)
      if (expirationTime.end) {
        clearTimeout(_this.expirationTimer)
        // _this.$message({
        //   message: '交易关闭',
        //   type: 'error',
        //   duration: 1500,
        //   onClose: () => {
        //     _this.goRouter('orderDetail')
        //   }
        // });
        return
      }
      _this.countDown(endTimeStamp)
      _this.expirationTime = expirationTime.hour + ':' + expirationTime.minute + ':' + expirationTime.second
    }, 1000)
  }
}
